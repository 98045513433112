<template>
  <BaseModal
    :model-value="modelValue"
    click-to-close
    classes="image-viewer"
    content-class="image-viewer__content"
    ssr
    esc-to-close
    :z-index="10"
    @opened="onOpenedModal"
    @before-close="onBeforeClose"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <div class="image-viewer__header">
      <button
        class="btn btn-secondary m-left-auto"
        @click="emitClose()"
      >
        Закрыть
        <SvgIcon src="navigation/close-20px" />
      </button>
    </div>
    <div class="image-viewer__container">
      <div
        v-show="hasControllers"
        class="image-viewer__button left"
        :class="{
          disabled: isFirstImage,
        }"
        @click="changeImage(EDirection.Prev)"
      >
        <div class="image-viewer__button-icon-wrapper">
          <SvgIcon
            class="image-viewer__button-icon"
            src="navigation/arrow-left-20px"
          />
        </div>
      </div>
      <div
        v-show="hasControllers"
        class="image-viewer__button right"
        :class="{
          disabled: isLastImage,
        }"
        @click="changeImage(EDirection.Next)"
      >
        <div class="image-viewer__button-icon-wrapper">
          <SvgIcon
            class="image-viewer__button-icon"
            src="navigation/arrow-right-20px"
          />
        </div>
      </div>
      <div
        ref="viewerContainer"
        class="image-viewer__viewer"
      >
        <div v-if="ssr">
          <BaseImg
            v-for="(image, index) in images"
            :key="index"
            :src="image"
            alt="img"
          />
        </div>
        <div v-else>
          <img
            v-for="(image, index) in images"
            :key="index"
            :src="image"
            alt="img"
          >
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script lang="ts" setup>
import BaseModal from '../modals/BaseModal.vue';
import SvgIcon from '../SvgIcon.vue';
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';
import BaseImg from 'shared/components/BaseImg';
import { WatchSubscription } from '../../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../../composables/useSSRUnsubscribeWatch';

enum EDirection {
  Next,
  Prev,
}

const props = withDefaults(
  defineProps<{
    // Статус модального окна
    modelValue?: boolean;
    // Массив картинок для отображения в модалке
    images: Array<string>;
    // Индекс изображения, на котором открывается окно
    initialIndex?: number;
    // Контроллеры перемещений по картинкам
    hasControllers?: boolean;
    // Для корректной работы события opened в nuxt ssr = true
    ssr?: boolean;
    // Контроль галереей с клавиатуры стреклами
    isKeyboardControlEnabled?: boolean;
  }>(),
  {
    ssr: true,
    isKeyboardControlEnabled: true,
  },
);

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean);
}>();

const watchSubscription = new WatchSubscription();
const viewerContainer = ref<HTMLElement>();
const viewer = ref<Viewer | undefined>();
const isFirstImage = ref(true);
const isLastImage = ref(false);

/** Флаг нажатия на стрелку на клавиатуре (для исключения множественного срабатывания) */
const isArrowPressed = ref(false);

/** Обработчик события нажатия на стрелки */
function onArrowDown(e: KeyboardEvent) {
  const targetKeys = ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'];

  if (isArrowPressed.value || !targetKeys.includes(e.key)) {
    return;
  }

  isArrowPressed.value = true;
  window.addEventListener('keyup', onArrowUp);

  switch (e.key) {
    case 'ArrowRight' :
    case 'ArrowUp' :
      if (!isLastImage.value) {
        changeImage(EDirection.Next);
      }
      break;
    case 'ArrowDown':
    case 'ArrowLeft':
      if (!isFirstImage.value) {
        changeImage(EDirection.Prev);
      }
      break;
    default: return;
  }
}

/** Обработчик события отпускания стрелки */
function onArrowUp() {
  isArrowPressed.value = false;
  window.removeEventListener('keyup', onArrowUp);
}

/** Инициализация слушателя для управления с клавиатуры */
function initKeyboardControl() {
  if (props.isKeyboardControlEnabled) {
    window.addEventListener('keydown', onArrowDown);
  }
}

/** Деактивация слушателя для управления с клавиатуры */
function destroyKeyboardControl() {
  if (props.isKeyboardControlEnabled) {
    window.removeEventListener('keydown', onArrowDown);
  }
}

function initViewer(initialIndex?: number): void {
  viewer.value = new Viewer(viewerContainer.value, {
    inline: true,
    movable: false,
    backdrop: false,
    zoomOnWheel: false,
    zoomOnTouch: true,
    button: false,
    toolbar: false,
    title: false,
    tooltip: false,
    initialViewIndex: initialIndex ?? 0,
    loop: false,
    navbar: props?.hasControllers,
    view(event) {
      isFirstImage.value = event.detail.index === 0;
      isLastImage.value = event.detail.index === props.images.length - 1;
    },
  });
}

function destroyViewer(): void {
  document.body.style.overflow = 'unset';
  viewer.value?.destroy();
  destroyKeyboardControl();
}

function onOpenedModal(): void {
  document.body.style.overflow = 'hidden';
  initViewer(props.initialIndex);
  initKeyboardControl();
}

function onBeforeClose(): void {
  destroyViewer();
}

function changeImage(direction: EDirection): void {
  switch (direction) {
    case EDirection.Next:
      viewer.value?.next();
      break;

    case EDirection.Prev:
      viewer.value?.prev();
      break;

    default:
      break;
  }
}

function emitClose(): void {
  emits('update:modelValue', false);
}

watchSubscription.add(
  watch(props.images, () =>
    nextTick(() => {
      viewer?.value.update();
    }),
  ),
);

useSSRUnsubscribeWatch(watchSubscription);
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

$root: '.image-viewer';
$viewer: '.viewer';

::v-deep(#{$root}) {
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep(#{$root}__content) {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #fff;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

::v-deep(#{$viewer}-navbar) {
  background-color: $viewer-navbar-bc;
}

::v-deep(#{$viewer}-container) {
  display: flex;
  flex-direction: column;
}

::v-deep(#{$viewer}-canvas) {
  position: relative;
  flex: 1;
}

::v-deep(#{$viewer}-footer) {
  position: relative;
}

::v-deep(#{$viewer}-list) {
  height: 96px !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  transform: none !important;
  margin-bottom: 40px;
  margin-top: 16px;

  li {
    width: 96px;
    height: 96px;
    margin-right: 8px;
    border: 1px solid rgba($color: $viewer-navbar-item-border, $alpha: 10%);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-items: center;
    padding: 5px;
    opacity: 1;

    &.viewer-active {
      border-color: $viewer-navbar-item-active-border;
    }

    &:last-child {
      margin-right: 0;
    }

    img {
      object-fit: contain;
      height: 100% !important;
      width: 100% !important;
      transform: none !important;
    }
  }
}

::v-deep(#{$root}__button-icon) {
  path {
    transition: all 0.2s;
  }
}

#{$root} {
  &__container {
    position: relative;
    height: 100%;
  }

  &__viewer {
    height: 100%;
    display: none;
  }

  &__header {
    height: 88px;
    padding: 40px 40px 12px;
  }

  &__button {
    position: absolute;
    height: 660px;
    width: 136px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 100;

    &:hover {
      ::v-deep(#{$root}__button-icon) {
        path {
          fill: $link;
        }
      }
    }

    &.left {
      top: 0;
      left: 0;
      bottom: 0;
    }

    &.right {
      top: 0;
      right: 0;
      bottom: 0;
    }

    &.disabled {
      cursor: not-allowed;

      ::v-deep(#{$root}__button-icon) {
        path {
          fill: $viewer-navigation-inactive;
        }
      }
    }

    &-icon {
      width: 20px;
      height: 20px;

      &-wrapper {
        padding: 44px 12px;
        background-color: $viewer-navigation-bc;
        border-radius: 8px;
      }
    }
  }

  .m-left-auto {
    margin-left: auto;
  }
}

@media screen and (max-width: 768px) {
  .image-viewer__header {
    padding: 32px 20px 0 0;
  }

  .image-viewer__button-icon-wrapper {
    display: none;
  }
}

@media screen and (max-width: 360px) {
  .image-viewer__header {
    padding: 20px 20px 0 0;

    .btn-secondary {
      font-size: 0;
      width: 36px;
      height: 36px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-left: 0;
      }
    }
  }

  .image-viewer__button-icon-wrapper {
    display: none;
  }
}

@media only screen and (min-width: 360px) and (max-width: 767px) {
  .image-viewer {
    &__button {
      display: none;
    }
  }
}
</style>
